// Read JSON file
fetch("./js/response.json")
  .then((response) => {
    if (!response.ok) {
      throw new Error("HTTP error " + response.status);
    }
    return response.json();
  })
  .then((json) => {
    this.screens = json;

    // Declare empty HTML variable to populate later
    let vidCardsHTML = "";

    // Loop through views array to get video details
    this.screens.screens[0].config.views.forEach(function (vidInfo) {
      const vidLibrary = document.querySelector("#video-library");

      // Loop through screens array
      this.screens.screens.forEach(function (vidURL) {
        // Compare video IDs from views and screens arrays to get corresponding video file URL
        if (vidInfo.id === vidURL.id) {
          // Find player in views array based on id + `Player` to get url from files array and use in data attributes
          let vidPlayer = vidURL.config.views.find(
              (o) => o.id === `${vidURL.id}Player`
            ),
            secureThumb = vidInfo.thumbnail.replace("http://", "https://"),
            secureMP4 = vidPlayer.files[0].url.replace("http://", "https://");
          // secureOGV = vidPlayer.files[2].url.replace("http://", "https://");

          // Build video card HTML with variables from JSON
          vidCardsHTML += `<div class="video-card">
              <div class="video-card-img" data-mp4="${secureMP4}" tabindex="0">
                <img src="${secureThumb}" alt="${vidInfo.title} video thumbnail">
                <div class="video-duration">${vidInfo.info}</div>
              </div>
              <div class="video-info">
                <h2>${vidInfo.title}</h2>
                <p>${vidInfo.description}</p>
              </div>
            </div>`;

          // Add each card to the existing container
          vidLibrary.innerHTML = vidCardsHTML;
        }
      });
    });

    // Find all video thumbnails
    const vidThumbnails = document.querySelectorAll(".video-card-img");

    // Loop through video thumbnails array and attach a click listener to each
    vidThumbnails.forEach((vid) =>
      vid.addEventListener("click", (event) => {
        // Get video URL from data attribute
        const vidMP4URL = vid.getAttribute("data-mp4"),
          vidOGVURL = vid.getAttribute("data-ogv"),
          vidThumbnail = vid.querySelector("img").getAttribute("src"),
          vidPlayer = document.querySelector("#video-player"),
          // Build video player with URL from data attribute
          vidPlayerHTML =
            // <video width="100%" controls poster="${vidThumbnail}"> // Removed poster because it briefly displays before playing video
            `<div id="video-player-cont">
                <video width="100%" controls>
                  <source src="${vidMP4URL}" type="video/mp4">
                  <source src="${vidOGVURL}" type="video/ogv">
                  <p>Your browser doesn't support HTML5 video. Here is a <a href="${vidMP4URL}" target="_blank">link to the video</a> instead.</p>
                </video>
              </div>`;

        // Toggle active video border
        const vidCards = document.querySelectorAll(".video-card");
        vidCards.forEach(function (vidCard) {
          vidCard.classList.remove("video-card-playing");
        });
        vid.closest(".video-card").classList.add("video-card-playing");

        // Create/replace the video player
        vidPlayer.innerHTML = vidPlayerHTML;

        // Toggle player class on video thumbnail to remove play button
        // vid.classList.toggle('video-card-player');

        // // Find all video elements and pause any video that is playing, but is not the (clicked) target
        // var videos = document.querySelectorAll('video');
        // // Loop through video elements array
        // videos.forEach(function(video) {
        //   video.addEventListener('play', function(event) {
        //     videos.forEach(function(video) {
        //       if(event.target !== video) {
        //         video.pause();
        //       }
        //     });
        //   });
        // });

        // Start playing clicked video
        vidPlayer.querySelector("video").play();

        // Go to top of page (especially for mobile)
        scroll(0, 0);
      })
    );
  })
  .catch(function () {
    this.dataError = true;
  });
